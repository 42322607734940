import React, { useState, useEffect } from "react"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Slider from "react-slick"
import { Link } from "gatsby"
import TitleUnderline from "./titleUnderline"


const ComparePrice = ({ data }) => {
  //funcion para slider de logos clientes
  var settings = {
    slidesToShow: 1,
    infinite: false,
    initialSlide: 0,
    dots: true,
    speed: 500,
    arrows: true,
  }

  const features = [
    "Usuarios creados",
    "Canales disponibles",
    "Widget webchat",
    "Posición de widget para webchat",
    "Todos los dashboards y reportes",
    "Supervisión en tiempo real",
    "Creación de productos",
    "Creación de buzones",
    "Creación de etiquetas",
    "Creación de campos customizados",
    "Creación de colas de atención",
    "Creación de plantillas de WhastApp",
    "Conversaciones al mes",
    "Tickets al mes",
    "Creación de chatbots",
    "Cuentas por canal",
    "WhatsApp masivos",
    "Subcuentas",  
    "Consumo de Beex",
    "Meses de almacenamiento",
    "Soporte por",
  ]

  const [windowsWidth, setWindowsWidth] = useState(true)
  useEffect(() => {
    let windowWidth = window.screen.width
    if (windowWidth < 1000) {
      setWindowsWidth(false)
    } else {
      setWindowsWidth(true)
    }
  }, [])

  const [fixedCabecera, setFixedCabecera] = useState(false)

  useEffect(() => {
    window.onscroll = function() {
      if (window.scrollY >= 1600) {
        setFixedCabecera(false)
      } else if (window.scrollY >= 200) {
        setFixedCabecera(true)
      } else {
        setFixedCabecera(false)
      }
    }
  }, [setFixedCabecera])

  return (
    <section className="container-price-ticker">
    
      <p className="container-price-ticker-title">
      <TitleUnderline underline="Precios" />   de Beex Conversations
      </p>


      {windowsWidth ? (
        <div className="container-boxprice-ticker">
          <div
            className={`${
              fixedCabecera ? "container-boxprice-ticker-true" : " "
            }`}
          >
            <div className="cabecera-price-ticker">
              <h2> Caracteristicas</h2>
              <div className="cabecera-price-ticker-planes">
                {data.map((elemento, i) => {
                  return (
                    <div
                      key={i}
                      className="container-price-ticker__containerfeatures__featureslogo"
                    >
                      <img
                        loading="lazy"
                        src={elemento.img}
                        alt="..."
                        className={elemento.classlogo}
                      />
                      <p className="container-price-ticker__containerfeatures__featurestitle">
                        {elemento.title}
                      </p>
                      {elemento.price}
                      <Link
                        href={elemento.link}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="btn-slider-price-white"
                      >
                        {elemento.boton}
                      </Link>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
          <div className={`${fixedCabecera ? "boxprice-ticker-true" : " "}`} />
          <section className="container-price-ticker__containerfeatures">
            {caracteristica(features, windowsWidth)}
            <div className="container-price-ticker__containerfeatures__features">
              {data.map(elemento => {
                return (
                  <div
                    className={`container-price-ticker__containerfeatures__features-detail ${Border(
                      elemento
                    )} `}
                    key={elemento}
                  >
                    {Detail(elemento, windowsWidth)}
                  </div>
                )
              })}
            </div>
          </section>
        </div>
      ) : (
        <Slider {...settings}>
          {data.map(elemento => {
            return (
              <>
                <section
                  className={`container-price-ticker__containerfeatures  ${Border(
                    elemento
                  )} `}
                  key={elemento}
                >
                  {caracteristica(features, windowsWidth)}
                  <div className="container-price-ticker__containerfeatures__features">
                    <div
                      className={`container-price-ticker__containerfeatures__features-detail`}
                    >
                      {Detail(elemento, windowsWidth)}
                    </div>
                  </div>
                </section>
              </>
            )
          })}
        </Slider>
      )}
    </section>
  )
}
export default ComparePrice

const Border = elemento => {
  if (elemento.title === "FREE") {
    return "border-free"
  }
  if (elemento.title === "ADVANCED") {
    return "price-border-avanced"
  }
  if (elemento.title === "BUSINESS") {
    return "border-busines"
  }
  if (elemento.title === "ENTERPRISE") {
    return "price-border-enterprise"
  }
}

const Detail = (elemento, windowsWidth) => {
  return (
    <div>
      {windowsWidth ? (
        ""
      ) : (
        <div className="container-price-ticker__containerfeatures__featureslogo">
          <img
            loading="lazy"
            src={elemento.img}
            alt="..."
            className={elemento.classlogo}
          />
          <p className="container-price-ticker__containerfeatures__featurestitle">
            {elemento.title}
          </p>
          {elemento.price}
          <Link
            href={elemento.link}
            target="_blank"
            rel="noopener noreferrer"
            className="btn-slider-price-white"
          >
            {elemento.boton}
          </Link>
        </div>
      )}

      {elemento.info.map((item, index) => {
        return (
          <div
            className={`container-price-ticker__containerfeatures__features-detail--info ${
              index % 2 === 0 ? "container-price-ticker__item-blue" : ""
            } `}
            key={index}
          >
            {item}
          </div>
        )
      })}
    </div>
  )
}

const caracteristica = (features, windowsWidth) => {
  return (
    <div className="container-price-ticker__containerfeatures__caracteristicas">
      {windowsWidth ? "" : <h2> Caracteristicas</h2>}

      {features.map((item, index) => (
        <div
          className={`container-price-ticker__item ${
            index % 2 === 0 ? "container-price-ticker__item-blue" : "blanco"
          } `}
          key={index}
        >
          <p>{item}</p>
        </div>
      ))}
    </div>
  )
}
