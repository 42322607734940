/* eslint-disable react/jsx-key */
import React from "react"

//logo de empresas clientes para mobile
import Rimac from "@components/sharedComponents/customersLogos/logos/Rimac.svg"
import Auna from "@components/sharedComponents/customersLogos/logos/Auna.svg"
import Unifef from "@components/sharedComponents/customersLogos/logos/Unifef.svg"
import Astara from "@components/sharedComponents/customersLogos/logos/Astara.svg"
import Cepeban from "@components/sharedComponents/customersLogos/logos/Cepeban.svg"
import CasaRossello from "@components/sharedComponents/customersLogos/logos/CasaRossello.svg"
import Proempresa from "@components/sharedComponents/customersLogos/logos/Proempresa.svg"
import Savar from "@components/sharedComponents/customersLogos/logos/Savar.svg"
import Cafae from "@components/sharedComponents/customersLogos/logos/Cafae.svg"
import Escampa from "@components/sharedComponents/customersLogos/logos/Escampa.svg"
import Royal from "@components/sharedComponents/customersLogos/logos/Royal.svg"
import G4S from "@components/sharedComponents/customersLogos/logos/G4S.svg"

//iconos de precio
import advanced from "@components/pageTicker/pagePrice/image/advanced.svg"
import business from "@components/pageTicker/pagePrice/image/business.svg"
import enterprise from "@components/pageTicker/pagePrice/image/enterprise.svg"

//logos channels
import whatsApp from "@components/pageTicker/pagePrice/image/whatsApp.svg"
import messenger from "@components/pageTicker/pagePrice/image/messenger.svg"
import instagram from "@components/pageTicker/pagePrice/image/instagram.svg"
import chat from "@components/pageTicker/pagePrice/image/chat.svg"
import sms from "@components/pageTicker/pagePrice/image/sms.svg"
import email from "@components/pageTicker/pagePrice/image/email.svg"

const checked = (
  <svg
    width="26"
    height="20"
    viewBox="0 0 26 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.5068 0.67906L9.91704 15.0584L3.4229 8.59475C2.64923 7.82192 1.38323 7.82192 0.586112 8.59475C-0.187558 9.36758 -0.187558 10.6322 0.586112 11.4285L8.5807 19.4144C8.95581 19.7891 9.47159 19.9999 9.98737 19.9999C10.0108 19.9999 10.0343 19.9999 10.0577 19.9999C10.6204 19.9765 11.1362 19.7423 11.4878 19.3207L25.4842 3.32543C26.211 2.48234 26.1172 1.24112 25.2967 0.491706C24.4995 -0.234289 23.2335 -0.140612 22.5068 0.67906Z"
      fill="#F7D731"
    />
  </svg>
)

const error = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_11620_7609)">
      <path
        d="M16.2269 19.3527L10 13.1257L3.7731 19.3527C2.9099 20.2158 1.51049 20.2158 0.647288 19.3527C-0.215763 18.4895 -0.215763 17.0901 0.647288 16.2269L6.87434 10L0.647288 3.7731C-0.215763 2.9099 -0.215763 1.51049 0.647288 0.647288C1.51049 -0.215763 2.9099 -0.215763 3.7731 0.647288L10 6.87434L16.2269 0.647288C17.0901 -0.215763 18.4895 -0.215763 19.3527 0.647288C20.2158 1.51049 20.2158 2.9099 19.3527 3.7731L13.1257 10L19.3527 16.2269C20.2158 17.0901 20.2158 18.4895 19.3527 19.3527C18.4895 20.2158 17.0901 20.2158 16.2269 19.3527Z"
        fill="#E76E54"
      />
    </g>
    <defs>
      <clipPath id="clip0_11620_7609">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

const channels = [whatsApp, messenger, instagram, chat, sms, email]

const channel = () => {
  return channels.map(item => {
    return <img src={item} alt="canal" key={item} />
  })
}

const infoTwo = [
  <p>Desde 1 a más</p>,
  <p>{channel()}</p>,
  <p>Personalización de responsive</p>,
  <p>Personalización de posición en web</p>,
  <p>{checked}</p>,
  <p>Sin monitoreo individual de agentes</p>,
  <p>100 productos</p>,
  <p>Ilimitado</p>,
  <p>Ilimitado</p>,
  <p>Ilimitado</p>,
  <p>4</p>,
  <p>50</p>,
  <p>Ilimitado</p>,
  <p>500</p>,
  <p>2</p>,
  <p>1</p>,
  <p>{error}</p>,
  <p>{error}</p>,
  <p>{error}</p>,
  <p>6</p>,
  <p>Beex Docs, chat, WhatsApp y email.</p>,
]

const infoThree = [
  <p>Desde 1 a más</p>,
  <p>{channel()}</p>,
  <p>Configuración de marca de agua</p>,
  <p>Personalización en responsive y web</p>,
  <p>{checked}</p>,
  <p>Con monitoreo individual de agentes</p>,
  <p>Ilimitado</p>,
  <p>Ilimitado</p>,
  <p>Ilimitado</p>,
  <p>Ilimitado</p>,
  <p>Ilimitado</p>,
  <p>250</p>,
  <p>Ilimitado</p>,
  <p>2000</p>,
  <p>4</p>,
  <p>2</p>,
  <p>{checked}</p>,
  <p>{error}</p>,
  <p>{error}</p>,
  <p>6</p>,
  <p>Beex Docs, chat, WhatsApp y email.</p>,
]

const infoFour = [
  <p>5 a 10 usuarios como mínimo</p>,
  <p>{channel()}</p>,
  <p>Configuración ilimitada de webchat</p>,
  <p>Personalización total</p>,
  <p>{checked}</p>,
  <p>Con monitoreo individual de agentes</p>,
  <p>Ilimitado</p>,
  <p>Ilimitado</p>,
  <p>Ilimitado</p>,
  <p>Ilimitado</p>,
  <p>Ilimitado</p>,
  <p>250 plantillas</p>,
  <p>Ilimitado</p>,
  <p>Ilimitado</p>,
  <p>Ilimitado</p>,
  <p>5 o màs</p>,
  <p>{checked}</p>,
  <p>{checked}</p>,
  <p>{checked}</p>,
  <p>Personalizado</p>,
  <p>Beex Docs, chat, WhatsApp, email y teléfono.</p>,
]

const priceTwo = (
  <p className="text-pricing-ticker-advanced">
    $19.99 <span>POR USUARIO</span>
  </p>
)
const priceThree = (
  <p className="text-pricing-ticker-busines">
    $29.99 <span>POR USUARIO</span>
  </p>
)
const priceFour = <p className="text-pricing-ticker">CONTACTAR CON VENTAS </p>

const data = {
  pricing: [
    {
      title: "ADVANCED",
      price: priceTwo,
      typePrice: "yes",
      classtitle: "card-title-inbox",
      img: advanced,
      info: infoTwo,
      link:
        "/hablemos/",
      boton: "Empezar",
      classlogo: "",
    },
    {
      title: "BUSINESS",
      price: priceThree,
      typePrice: "yes",
      classtitle: "card-title-inbox",
      img: business,
      info: infoThree,
      link:
        "/hablemos/",
      boton: "Empezar",
      classlogo: "logo-busines",
    },
    {
      title: "ENTERPRISE",
      price: priceFour,
      typePrice: "no",
      classtitle: "card-title-inbox",
      img: enterprise,
      info: infoFour,
      link: "/hablemos/",
      boton: "Contactar",
      classlogo: "logo-enterprise",
    },
  ],

  question: [
    {
      title: "¿Cuándo escojo una licencia, lo hago sobre todo mi equipo?",
      textbody:
        "¡Correcto! Cuando tomas una licencia de pago o haces upgrade a una de estas, todos los usuarios pasan a este nuevo precio.",
    },
    {
      title: "¿Puedo cancelar cuándo quiera?",
      textbody:
        "¡Correcto! Eres libre de cancelar tu suscripción en el momento que quieras. Toma en cuenta que si ya has pagado por licencias, tu suscripción durará hasta el final de tu ciclo de facturación, para luego aplicar tu baja. Aunque te advertimos… no querrás dejarnos ❤️.",
    },
    {
      title: "¿Qué pasa cuando agrego nuevos usuarios?",
      textbody:
        "Solo se te cobra por los días usados de estos nuevos usuarios (prorrateo, $3 como mínimo). Luego, al iniciar tu nuevo mes (ciclo de facturación), el costo será por el de la licencia escogida.",
    },
    {
      title: "¿Hay diferencias entre plan mensual y ciclo de facturación?",
      textbody:
        "No, usamos ambos términos y son lo mismo. Este tiempo dura 30 días luego del pago. Por ejemplo: Activas tu licencia de pago un 12/Julio, entonces tu ciclo de facturación iniciará los 13 de cada mes.",
    },
    {
      title: "¿Qué pasa si hay problemas con mi tarjeta de crédito?",
      textbody:
        "Brindamos 3 días de gracia para esperar que pase el pago. Si seguimos sin aprobación, no podrás usar Beex hasta actualizar tu método de pago. Recuerda que luego, eres libre de hacer downgrade a tu licencia.",
    },
    {
      title: "¿Qué métodos de pago aceptan?",
      textbody:
        "Aceptamos las principales tarjetas de crédito, como Visa o Mastercard. Para licencia Enterprise, debe existir un compromiso por 12 meses como mínimo y se aceptan transferencias bancarias.",
    },
    {
      title: "¿Qué pasa cuando hago downgrade a una licencia menor?",
      textbody:
        "Te consultaremos 3 días antes que venza tu plan actual por las características que quieres mantener, como cuáles son los chatbots que estarán contigo y por lo tanto, cuáles ya no. Además, se eliminarán las etiquetas, campos customizados y buzones según su fecha de creación.",
    },
    {
      title: "¿Hay descuentos para organizaciones sin fines de lucro?",
      textbody:
        "Lo hacemos ❤️. Para organizaciones que ayuden a los más necesitados, estamos para darles una mano. Pueden escribirnos aquí para conversar.",
      hr: true,
    },
  ],

  customersDesktop: [
    Rimac,
    Auna,
    Unifef,
    Astara,
    Cepeban,
    CasaRossello,
    Proempresa,
    Savar,
    Cafae,
    Escampa,
    Royal,
    G4S
  ],
  customersTablet: {
    arrayOne: [Rimac, Auna, Unifef, Astara, Cepeban, CasaRossello],
    arrayTwo: [Proempresa, Savar, Cafae, Escampa, Royal, G4S],
  },
  footer: {
    product: "PRODUCTOS",
    productDesktopOne: "Conversations: Atención al cliente omnicanal y chatbots.",
    productMobileOne: "Conversations",
    linkone: "/conversations/",
    productDesktopTwo: " Contact Center: Software en la nube para Contact Center.",
    productMobileTwo: "Contact Center",
    linktwo: "/contact-center/",
    productDesktopFour: "Validation: Software de validación de teléfono.",
    productMobileFour: "Validation",
    linkfour: "/validation/",

    solution: "SOLUCIONES",
    solutionOne: "Atención al cliente",
    linkfive: "/atencion-al-cliente/",
    solutionTwo: "Telemarketing",
    linksix: "/marketing-ventas/",
    solutionThree: "Contact Center",
    linkseven: "/contact-center/",
    solutionFour: "Cobranzas",
    linkeight: "/software-cobranzas/",
    solutionFive: "WhatsApp",
    linkwhatsapp: "/whatsapp-business-api/",
    linkchatbots: "/chatbots-online/",
    solutionSix: "Chatbots",
    linkinstagram: "/chatbot-instagram/",
    solutionSeven: "Instagram",
    linkwebchat: "/chat-en-vivo-web/",
    solutionEight: "WebChat",

    company: "EMPRESA",
    companyOne: "Nosotros",
    linknine: "/nosotros/",
    companyTwo: "Casos de éxito",
    linkten: "/casos-de-exito/",
    companyThree: "Blog",
    linkeleven: "https://beexcc.com/blog/",
    companyFour: "Hablemos",
    linktwelve: "/hablemos/",
    companySix: "Documentación",
    linkdocs: "https://beexcc.com/docs/",
    companyFive: "Recursos",
    linktthirteen: "/recursos/",

    blog: "BLOG",
    blogone: "¿Cómo elegir el mejor software para call center?",
    linkblogone: "https://beexcc.com/blog/mejor-software-call-center/",

    blogtwo: "Plataforma Omnicanal: Qué es y cómo funciona",
    linkblogtwo: "https://beexcc.com/blog/plataforma-omnicanal/",

    blogthree: "¿Qué es WhatsApp Business API? - Guía completa 2023",
    linkblogthree: "https://beexcc.com/blog/que-es-whatsapp-business-api/",

    blogfour:
      "¿Cómo elegir el mejor chatbot? 5 características que debe cumplir",
    linkblogfour: "https://beexcc.com/blog/mejor-chatbot/",

    blogfive: "9 características que las herramientas de telemarketing",
    linkblogfive: "https://beexcc.com/blog/herramientas-telemarketing/",

    masblog: "Conoce más sobre nuestro blog",
    linkmasblog: "https://beexcc.com/blog/",

    address: "Jirón Pachacutec 1315, Of. 503, Jesús María, Lima, Perú",
    phone: "(+511) 277-4188",
    mail: "hi@beexcc.com",
    copyright: "Copyright© 2024 Beex Perú. Todos los derechos reservados.",
    privacyPolicy: "Política de Privacidad",
    privacyPolicyHref: "/politicas-privacidad",
    cookiesPolicy: "Política de Cookies",
    cookiesPolicyHref: "/politicas-cookies",
  },
}

export default data
